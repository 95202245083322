<i18n src="@/locales/dental_bridge.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="dental-bridge">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{ $t('article_p2') }}
        </p>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <p>
          {{ $t('article_p3') }}
        </p>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{ $t('article_p4') }}
        </p>
        <p>
          {{ $t('article_p5') }}
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{ $t('collapse_p4_1') }}
          </p>
          <table class="table">
            <thead>
              <tr>
                <th />
                <th v-html="$t('th_1')" />
                <th v-html="$t('th_2')" />
                <th v-html="$t('th_3')">
                  ️
                </th>
                <th v-html="$t('th_4')" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <th v-html="$t('th_5')" />
                <td>3000€</td>
                <td>750€</td>
                <td>750€</td>
                <td>750€</td>
              </tr>
              <tr>
                <th v-html="$t('th_6')" />
                <td>3600€</td>
                <td>870€</td>
                <td>850€</td>
                <td>850€</td>
              </tr>
              <tr>
                <th v-html="$t('th_7')" />
                <td>3900€</td>
                <td>1150€</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <p>
            {{ $t('collapse_p4_2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_5')"
        >
          <div v-html="$t('collapse_p5_1')" />
          <br>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
          <br>
          <div v-html="$t('collapse_p5_2')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_6')"
        >
          <p>
            {{ $t('collapse_p6') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_2')">
            {{ $t('more_info_2') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'DentalBridge',
    components: {
      ImanMoreInfo,
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [83, 63, 52, 46],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-care/bridge/definition-bridge-dentaire.jpg',
            position: 'right'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_3.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/dental-care/bridge/H1-bridge-dentaire-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/dental-care/bridge/H1-bridge-dentaire-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../assets/img/dental-care/bridge/H1-bridge-dentaire-m.jpg");
    }
  }
</style>
