import { render, staticRenderFns } from "./DentalBridge.vue?vue&type=template&id=3b039859&scoped=true"
import script from "./DentalBridge.vue?vue&type=script&lang=js"
export * from "./DentalBridge.vue?vue&type=script&lang=js"
import style0 from "./DentalBridge.vue?vue&type=style&index=0&id=3b039859&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b039859",
  null
  
)

/* custom blocks */
import block0 from "@/locales/dental_bridge.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-care%2Fdental-bridge%2FDentalBridge.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-care%2Fdental-bridge%2FDentalBridge.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports